import React, {ReactNode} from 'react';

import {useTabNav} from '../hooks';

type Props = {
  tab: string
  children: ReactNode
}

const TabNavContent = ({
                         tab,
                         children
                       }: Props) => {
  const {selectedTab} = useTabNav();

  return <>
    {selectedTab === tab && children}
  </>;
};

export default TabNavContent;