import React, {ReactNode, useCallback, useState} from 'react';

import TabNavContext from './TabNavContext';
import {Tab, TabNavUpdate} from '../types';

type Props = {
  children: ReactNode
  tabs: Tab[]
  initialTab?: string
}

const TabNavProvider = ({
                          children,
                          tabs,
                          initialTab
                        }: Props) => {
  const [selectedTab, setSelectedTab] = useState(initialTab || tabs[0].value);
  const [tabState, setTabState] = useState<Tab[]>(tabs);

  const setTabDisplayValue = useCallback(
    (tabValue: string, tabDisplayValue: string) => {
      const newTabs = [...tabState];
      newTabs.filter(tab => tab.value === tabValue)[0].displayValue = tabDisplayValue;
      setTabState(newTabs);
    }, []);

  const setTabs = ({tabs, initialTab}: TabNavUpdate) => {
    setTabState(tabs);
    setSelectedTab(initialTab || tabs[0].value);
  };

  return <TabNavContext.Provider value={{
    setSelectedTab,
    selectedTab,
    setTabDisplayValue,
    tabs: tabState,
    setTabs
  }}>
    {children}
  </TabNavContext.Provider>;
};

export default TabNavProvider;