import React, {useCallback, useEffect, useState} from 'react';

import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {UserNotification} from '../types';
import {Duration, DurationType} from '../util';

type Props = {
  ssoApi: {
    findNotifications: () => Promise<UserNotification>
  }
  onNotificationsClick: () => void
}

const SsoNotificationButton = ({
                                 ssoApi,
                                 onNotificationsClick
                               }: Props) => {
  const [userNotification, setUserNotification] = useState<UserNotification>({
    unread: [],
    read: [],
    archived: []
  });

  const findNotifications = useCallback(
    async () => {
      try {
        const userNotification = await ssoApi.findNotifications();
        setUserNotification(userNotification);
      } catch (error) {
        // the interval will try again, so silently fail
      }
    },
    [ssoApi]
  );

  useEffect(() => {
      findNotifications().then();
      // poll every 10 minutes
      const interval = setInterval(findNotifications, Duration.of(10, DurationType.MINUTES));
      // clear the interval out when input change
      return () => {
        clearInterval(interval);
      };
    },
    [findNotifications]
  );

  return <Button color="link"
                 onClick={() => onNotificationsClick()}
                 className="SsoNotificationButton">
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon="bell"/>
        <span className="fa-layers-counter notification-badge">{userNotification.unread.length}</span>
      </span>
  </Button>;
};

export default SsoNotificationButton;