import {createContext} from 'react';
import {Alert, AlertsContextInterface} from '../types';

const AlertsContext = createContext<AlertsContextInterface>({
  alerts: <Alert[]>[],
  removeAlert: (alertId: number) => console.log('removeAlert', alertId),
  showSuccessAlert: (message: string) => console.log('showSuccessAlert', message),
  showErrorAlert: (message: string) => console.log('showErrorAlert', message),
  timeout: 5000
});

export default AlertsContext;