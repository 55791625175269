import React, {ElementType, PropsWithoutRef} from 'react';

import {TabNavProvider} from '../contexts';
import {Tab} from '../types';

type Props = {
  tabs: Tab[]
  initialTab?: string
}

const withTabNav = (Component: ElementType, {tabs, initialTab}: Props) => {
  return (props: PropsWithoutRef<any>) => {
    return <TabNavProvider tabs={tabs} initialTab={initialTab}>
      <Component {...props}/>
    </TabNavProvider>;
  };
};

export default withTabNav;