import React from 'react';
import {CustomInput, FormFeedback, FormGroup, Label} from 'reactstrap';
import {FieldHelperProps, useField, useFormikContext} from 'formik';

type RadioButton = {
  id?: string
  value?: string
  labelText?: string
  ariaLabel?: string
  disabled?: boolean
  disableOnSubmit?: boolean
}

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: any
  name: string
  labelText?: string
  inline?: boolean
  formGroupClass?: string
  disabled?: boolean
  radioButtons: RadioButton[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, helpers: FieldHelperProps<any>) => void
  noFormikOnChange?: boolean
}

const FormikRadioGroup = ({
                            name,
                            labelText,
                            inline,
                            formGroupClass,
                            disabled,
                            radioButtons,
                            onChange,
                            noFormikOnChange,
                            ...otherProps
                          }: Props) => {
  const [field, meta, helpers] = useField(name);
  const {isSubmitting, validateOnMount} = useFormikContext();
  const isInvalid = validateOnMount ? !!meta.error : !!(meta.error && meta.touched);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // Don't perform Formik's default on change if noFormikOnChange is true
    if (!noFormikOnChange) {
      await field.onChange(e);
    }

    // Call additional on change function if provided
    if (onChange) {
      onChange(e, helpers);
    }
  };

  return (
    <FormGroup className={formGroupClass}>
      {labelText && <Label className="label-static">{labelText}</Label>}
      <div>
        {radioButtons.map((radioButton, index) => {
          const idToUse = radioButton.id ? radioButton.id : `${field.name}Radio${index}`;
          const isChecked = field.value === radioButton.value;
          const ariaLabel = (radioButton.ariaLabel ? radioButton.ariaLabel : radioButton.labelText) + (isChecked ? ' selected' : '');
          return (
            <CustomInput key={index}
                         {...field}
                         {...otherProps}
                         type="radio"
                         id={idToUse}
                         value={radioButton.value}
                         label={radioButton.labelText}
                         aria-label={ariaLabel}
                         inline={inline}
                         onChange={handleChange}
                         disabled={disabled || radioButton.disabled || (isSubmitting && radioButton.disableOnSubmit !== true)}
                         checked={isChecked}
                         invalid={isInvalid}/>
          );
        })}
      </div>
      <FormFeedback style={isInvalid ? {display: 'block'} : {display: 'none'}}>{meta.error}</FormFeedback>
    </FormGroup>
  );
};

export default FormikRadioGroup;