import * as Yup from 'yup';

const PHONE_REGEX = /^$|\([2-9][0-9][0-9]\) [0-9][0-9][0-9]-([0-9][0-9][0-9][0-9]$)|([0-9][0-9][0-9][0-9] x[0-9]+$)/;

class YupPhone {
  private readonly _schema = Yup.string();

  constructor(message: string = 'Must be a valid phone number.') {
    this._schema = this._schema.matches(PHONE_REGEX, message);
  }

  schema() {
    return this._schema;
  }
}

export default YupPhone;