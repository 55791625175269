// msSaveOrOpenBlob is deprecated but has been removed from types in Typescript
// this is a band-aid for Edge browsers still using the deprecated feature.
declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void;
  }
}

const filenameRegex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i;

export const extractFilenameFromResponse = (response: Response): string => {
  const disposition = response.headers.get('content-disposition');
  if (disposition && (disposition.indexOf('attachment') !== -1 || disposition.indexOf('inline') !== -1)) {
    const matches = filenameRegex.exec(disposition);
    if (matches && matches[2]) {
      return matches[2];
    }
  }

  throw new Error('Unexpected content-disposition response header. Unable to extract filename.');
};

export const downloadFile = async (urlPromise: Promise<string>,
                                   filename?: string,
                                   handleError?: () => void) => {
  try {
    const url = await urlPromise;
    forceDownload(url, filename);
  } catch (error) {
    // If error handler supplied use it, otherwise throw error and let caller manage it.
    if (handleError) {
      handleError();
    } else {
      throw error;
    }
  }
};

export const downloadBlob = (blob: Blob, filename: string) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    forceDownload(url, filename, true);
  }
};

const forceDownload = (url: string, filename: string = '', isBlob: boolean = false) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(() => {
    if (isBlob) {
      window.URL.revokeObjectURL(url);
    }
    document.body.removeChild(a);
  }, 0);
};