import React, {ReactNode} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Form, Formik, FormikHelpers, FormikProps, FormikValues} from 'formik';
import {BaseSchema} from 'yup';

import {BootstrapColor, BootstrapModalSize} from '../types';

type Props = {
  autoFocus?: boolean
  isOpen: boolean
  title?: string
  size?: BootstrapModalSize
  showCloseIcon?: boolean
  submitButtonText?: string
  submitButtonColor?: BootstrapColor
  submitDisabled?: boolean
  cancelButtonText?: string
  cancelButtonColor?: BootstrapColor
  cancelDisabled?: boolean
  onCancel: (formikProps: FormikProps<any>) => void
  children: ReactNode
  initialValues: object
  validationSchema: BaseSchema
  onSubmit: (values: FormikValues, actions: FormikHelpers<any>) => void
}

const FormikModal = ({
                       autoFocus = false,
                       isOpen,
                       title,
                       size,
                       showCloseIcon = true,
                       submitButtonText = 'Submit',
                       submitDisabled,
                       submitButtonColor = 'success',
                       cancelButtonText = 'Cancel',
                       cancelButtonColor = 'secondary',
                       cancelDisabled,
                       onCancel,
                       children,
                       initialValues,
                       validationSchema,
                       onSubmit
                     }: Props) => {

  return <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    enableReinitialize={true}
    validateOnMount={true}
    onSubmit={onSubmit}>
    {(formikProps) => {
      const submitButtonDisabled = submitDisabled === undefined ?
        (!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty) : submitDisabled;
      const cancelButtonDisabled = cancelDisabled === undefined ? formikProps.isSubmitting : cancelDisabled;
      return (
        <Modal isOpen={isOpen}
               size={size}
               autoFocus={autoFocus}
               toggle={() => onCancel(formikProps)}
               aria-modal={true}
               returnFocusAfterClose={true}
               backdrop="static">
          <ModalHeader className="h5" toggle={showCloseIcon ? () => onCancel(formikProps) : undefined} tag="h2">
            {title}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
              {children}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color={submitButtonColor}
              className="mr-1"
              disabled={submitButtonDisabled}
              onClick={formikProps.submitForm}>
              {submitButtonText}
            </Button>
            <Button color={cancelButtonColor}
                    disabled={cancelButtonDisabled}
                    onClick={() => onCancel(formikProps)}>
              {cancelButtonText}
            </Button>
          </ModalFooter>
        </Modal>
      );
    }}
  </Formik>;
};

export default React.memo(FormikModal);