import {createContext} from 'react';

import {UserContextInterface} from '../types';

const UserContext = createContext<UserContextInterface>({
  currentUser: undefined,
  loadingUser: true,
  permissions: {}
});

export default UserContext;