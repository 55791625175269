import React from 'react';
import {CustomInput, FormFeedback, FormGroup, Label} from 'reactstrap';
import {FieldInputProps, useFormikContext} from 'formik';

type Checkbox = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: any
  id?: string
  name: string
  value?: string
  labelText?: string
  ariaLabel?: string
  disabled?: boolean
  disableOnSubmit?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  noFormikOnChange?: boolean
}

type Props = {
  labelText?: string
  type?: 'checkbox' | 'switch'
  inline?: boolean
  formGroupClass?: string
  disabled?: boolean
  checkboxes: Checkbox[]
}

const FormikCheckboxGroup = ({
                               labelText,
                               type = 'checkbox',
                               inline,
                               formGroupClass,
                               disabled,
                               checkboxes
                             }: Props) => {
  const {getFieldProps, getFieldMeta, setFieldValue, isSubmitting, validateOnMount} = useFormikContext();

  const handleChange = (checkbox: Checkbox, fieldProps: FieldInputProps<any>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    // Don't perform Formik's default on change if noFormikOnChange is true
    if (!checkbox.noFormikOnChange) {
      // Manipulate the string array for the multi-value case
      if (fieldProps.value instanceof Array) {
        const newValues = [...fieldProps.value];
        if (!newValues.includes(checkbox.value)) {
          newValues.push(checkbox.value);
          setFieldValue(checkbox.name, newValues);
        } else {
          setFieldValue(checkbox.name, newValues.filter(item => item !== checkbox.value));
        }
      } else {
        fieldProps.onChange(e);
      }
    }

    // Call additional on change function if provided
    if (checkbox.onChange) {
      checkbox.onChange(e);
    }
  };

  const renderFormFeedback = () => {
    let isInvalid = false;
    const errors: string[] = [];
    checkboxes.forEach(checkbox => {
      const fieldMeta = getFieldMeta(checkbox.name);
      isInvalid = validateOnMount ? !!fieldMeta.error : !!(fieldMeta.error && fieldMeta.touched);
      if (isInvalid && fieldMeta.error) {
        // Avoid duplicate errors for multi-value scenario
        if (!errors.includes(fieldMeta.error)) {
          errors.push(fieldMeta.error);
        }
      }
    });
    return (
      <FormFeedback style={isInvalid ? {display: 'block'} : {display: 'none'}}>
        {errors.join(', ')}
      </FormFeedback>
    );
  };

  return (
    <FormGroup className={formGroupClass}>
      {labelText && <Label className="label-static">{labelText}</Label>}
      <div>
        {checkboxes.map((checkbox, index) => {
          const {
            id,
            name,
            value,
            labelText,
            ariaLabel,
            onChange,
            noFormikOnChange,
            disableOnSubmit,
            ...otherProps
          } = checkbox;
          const fieldProps = getFieldProps(name);
          const fieldMeta = getFieldMeta(name);
          const isInvalid = validateOnMount ? !!fieldMeta.error : !!(fieldMeta.error && fieldMeta.touched);
          // Append value to the ID in the multi-value checkbox case,
          // otherwise IDs will be the same and this will cause confusion
          // with the for attribute on the labels
          const idToUse = id ? id : `${name}${type === 'checkbox' ? 'Checkbox' : 'Switch'}${value ? value : ''}`;

          return (
            <CustomInput {...fieldProps}
                         {...otherProps}
                         key={index}
                         type={type}
                         id={idToUse}
                         label={checkbox.labelText}
                         aria-label={checkbox.ariaLabel ? checkbox.ariaLabel : labelText}
                         inline={inline}
                         onChange={handleChange(checkbox, fieldProps)}
              // Multi value checkboxes should be of type array versus boolean. Evaluate checked based on that.
                         checked={fieldProps.value instanceof Array ? fieldProps.value.includes(value) : fieldProps.value}
                         invalid={isInvalid}
                         disabled={disabled || checkbox.disabled || (isSubmitting && checkbox.disableOnSubmit !== true)}/>
          );
        })}
        {renderFormFeedback()}
      </div>
    </FormGroup>
  );
};

export default FormikCheckboxGroup;
