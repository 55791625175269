import React, {ReactNode, useCallback, useEffect, useState} from 'react';

import UserContext from './UserContext';
import {User, UserPermissions} from '../types';
import {SsoSpringRestApi} from '../restApi';
// Use default import SsoSessionExpirationModal to prevent circular dependency loading error
import SsoSessionExpirationModal from '../components/SsoSessionExpirationModal';

type Props = {
  children: ReactNode
  ssoApi: SsoSpringRestApi
  setPermissions?: (currentUser?: User) => UserPermissions
}

const UserProvider = ({
                        children,
                        ssoApi,
                        setPermissions
                      }: Props) => {
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [loadingUser, setLoadingUser] = useState(true);
  const [isSessionExpirationModalOpen, setIsSessionExpirationModalOpen] = useState(false);
  const permissions = setPermissions ? setPermissions(currentUser) : {};

  useEffect(() => {
    const loadCurrentUser = async () => {
      try {
        await ssoApi.loginFromRefreshToken();
        const currentUser = await ssoApi.currentUser();
        setCurrentUser(currentUser);
        setLoadingUser(false);
      } catch (error) {
        await ssoApi.redirectToLogin();
      }
    };

    void loadCurrentUser();
  }, [
    ssoApi.currentUser,
    ssoApi.loginFromRefreshToken,
    setCurrentUser,
    setLoadingUser
  ]);

  const handleSetSessionExpirationModalOpen = useCallback((isOpen: boolean) => {
    ssoApi.setSessionWarningModalOpen(isOpen);
    setIsSessionExpirationModalOpen(isOpen);
  }, []);

  // Setup session monitoring
  useEffect(() => {
    const interval = ssoApi.monitorSession(() => handleSetSessionExpirationModalOpen(true));
    return () => clearInterval(interval);
  }, [
    handleSetSessionExpirationModalOpen
  ]);

  return (
    <UserContext.Provider value={{loadingUser, currentUser, permissions}}>
      {children}
      {currentUser &&
        <SsoSessionExpirationModal isOpen={isSessionExpirationModalOpen}
                                   setIsOpen={handleSetSessionExpirationModalOpen}
                                   ssoApi={ssoApi}/>
      }
    </UserContext.Provider>
  );
};

export default UserProvider;