import {Duration, DurationType} from '../util';

type ConfigOptions = {
  webUrl: string
  apiUrl: string
  jwtKey?: string
  lastActivityCookieName: string
  lastActivityCookieDomain: string
  sessionExpirationModalOpenKey?: string
  maxSession?: number
  sessionWarningAt?: number
}

class SsoSpringConfig {
  private _config: {
    webUrl: string
    apiUrl: string
    jwtKey: string
    lastActivityCookieName: string
    lastActivityCookieDomain: string
    sessionExpirationModalOpenKey: string
    maxSession: number
    sessionWarningAt: number
  };

  constructor(config: ConfigOptions) {
    const defaultConfig = {
      jwtKey: 'JWT',
      sessionExpirationModalOpenKey: 'sessionExpirationModalOpen',
      maxSession: Duration.of(30, DurationType.MINUTES), // 30 minute default
      sessionWarningAt: Duration.of(2, DurationType.MINUTES) // Warn when time left is at 2 minutes or less
    };
    this._config = {...defaultConfig, ...config};
  }

  get webUrl() {
    return this._config.webUrl;
  }

  get apiUrl() {
    return this._config.apiUrl;
  }

  get jwtKey() {
    return this._config.jwtKey;
  }

  get lastActivityCookieName() {
    return this._config.lastActivityCookieName;
  }

  get lastActivityCookieDomain() {
    return this._config.lastActivityCookieDomain;
  }

  get maxSession() {
    return this._config.maxSession;
  }

  get sessionWarningAt() {
    return this._config.sessionWarningAt;
  }

  get sessionExpirationModalOpenKey(){
    return this._config.sessionExpirationModalOpenKey;
  }
}

export default SsoSpringConfig;