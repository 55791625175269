import {User} from '../types';
import {MiSuiteRole} from '../misuite';
import {ProSuiteRole} from '../prosuite';

export const hasJurisdictionAndRole = (currentUser: User | null | undefined, jurisdictionName: string, roleName: string) => {
  if (currentUser && currentUser.jurisdictionRoles) {
    return currentUser.jurisdictionRoles.some(jurisdictionRole => jurisdictionRole.jurisdiction.name === jurisdictionName && jurisdictionRole.role.name === roleName);
  } else {
    return false;
  }
};

export const hasAnyRoles = (currentUser: User | null | undefined, roleNames: string[]) => {
  if (currentUser && currentUser.jurisdictionRoles) {
    return currentUser.jurisdictionRoles.some(jurisdictionRole => roleNames.includes(jurisdictionRole.role.name));
  } else {
    return false;
  }
};

export const hasAnyRolesForJurisdiction = (currentUser: User | null | undefined, roleNames: string[], jurisdictionName: string) => {
  if (currentUser && currentUser.jurisdictionRoles) {
    return currentUser.jurisdictionRoles.some(jurisdictionRole => roleNames.includes(jurisdictionRole.role.name) && jurisdictionRole.jurisdiction.name === jurisdictionName);
  } else {
    return false;
  }
};

export const hasMiSuiteAdminRole = (currentUser: User | null | undefined, appName: string) => {
  if (currentUser && currentUser.jurisdictionRoles) {
    return hasJurisdictionAndRole(currentUser, appName, MiSuiteRole.ADMIN);
  } else {
    return false;
  }
};

export const hasProSuiteAdminRole = (currentUser: User | null | undefined, appName: string) => {
  if (currentUser && currentUser.jurisdictionRoles) {
    return hasJurisdictionAndRole(currentUser, appName, ProSuiteRole.ADMIN);
  } else {
    return false;
  }
};

export const hasJurisdictionCanonicalIdAndRole = (currentUser: User | null | undefined, jurisdictionCanonicalId: number, roleName: string) => {
  if (currentUser && currentUser.jurisdictionRoles) {
    return currentUser.jurisdictionRoles.some(jurisdictionRole => jurisdictionRole.jurisdiction.canonicalId === jurisdictionCanonicalId && jurisdictionRole.role.name === roleName);
  } else {
    return false;
  }
};