import React, {isValidElement, ReactNode} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';

import ProgressIndicator from './ProgressIndicator';

type Props = {
  isOpen: boolean
  title: ReactNode
  content: ReactNode
}

const ProgressModal = ({
                         isOpen,
                         title,
                         content
                       }: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div className="mb-2">
          <ProgressIndicator/>
        </div>
        {isValidElement(content) ? content : <p>{content}</p>}
      </ModalBody>
    </Modal>
  );
};

export default ProgressModal;