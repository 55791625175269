import React, {useContext, useMemo} from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';

import {TabNavContext} from '../contexts';
import {Tab} from '../types';

const TabNav = () => {
  const {selectedTab, setSelectedTab, tabs} = useContext(TabNavContext);

  const renderTab = useMemo(() => (tab: Tab, index: number) => {
    const tabClassName = `text-nowrap ${index !== tabs.length - 1 ? 'mr-2' : ''}`;
    
    return <NavItem className={tabClassName} key={tab.value}>
      <NavLink href="#"
               onClick={() => setSelectedTab(tab.value)}
               active={selectedTab === tab.value}
               className="px-1">
        {tab.displayValue}
      </NavLink>
    </NavItem>;
  }, [selectedTab]);

  return <Nav tabs className="flex-wrap border-bottom-0">
    {tabs.map(renderTab)}
  </Nav>;
};

export default TabNav;