export enum DurationType {
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  MILLISECONDS = 'MILLISECONDS',
  DAYS = 'DAYS',
  YEARS = 'YEARS',
  WEEKS = 'WEEKS'
}

export class Duration {
  private readonly _durationInMilliseconds: number;

  constructor(durationValue: number, durationType: DurationType) {
    if (durationType === DurationType.MILLISECONDS) {
      this._durationInMilliseconds = durationValue;
    } else if (durationType === DurationType.SECONDS) {
      this._durationInMilliseconds = durationValue * 1000;
    } else if (durationType === DurationType.MINUTES) {
      this._durationInMilliseconds = durationValue * 1000 * 60;
    } else if (durationType === DurationType.HOURS) {
      this._durationInMilliseconds = durationValue * 1000 * 60 * 60;
    } else if (durationType === DurationType.DAYS) {
      this._durationInMilliseconds = durationValue * 1000 * 60 * 60 * 24;
    } else if (durationType === DurationType.WEEKS) {
      this._durationInMilliseconds = durationValue * 1000 * 60 * 60 * 24 * 7;
    } else if (durationType === DurationType.YEARS) {
      this._durationInMilliseconds = durationValue * 1000 * 60 * 60 * 24 * 365;
    } else {
      this._durationInMilliseconds = durationValue;
    }
  }

  toNumber(): number {
    return this._durationInMilliseconds;
  }

  static of(durationValue: number, durationType: DurationType): number {
    return new Duration(durationValue, durationType).toNumber();
  }
}
