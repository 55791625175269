import {createContext} from 'react';

import {TabNavContextInterface} from '../types';

const TabNavContext = createContext<TabNavContextInterface>({
  selectedTab: undefined,
  setTabDisplayValue: () => {
  },
  setSelectedTab: () => {
  },
  tabs: [],
  setTabs: () => {
  }
});

export default TabNavContext;