import React from 'react';
import {Col, Pagination, PaginationItem, PaginationLink, Row} from 'reactstrap';

type Props = {
  className?: string
  currentPage: number
  handleChange: (nextPage: number) => void
  totalItems: number
  perPage: number
  recordName?: string
  allowShowAll?: boolean
  showingAll?: boolean
  setShowingAll?: (showingAll: boolean) => void
}

const Paginator = ({
                     className,
                     currentPage,
                     handleChange,
                     totalItems,
                     perPage = 50,
                     recordName = 'records',
                     allowShowAll = false,
                     showingAll = false,
                     setShowingAll
                   }: Props) => {
  const paginatorClassName = className ? `${className} Paginator` : 'Paginator';
  const rangeStart = (currentPage * perPage);
  const rangeEnd = (rangeStart + perPage) >= totalItems ? totalItems : (rangeStart + perPage);
  const totalPages = Math.floor((totalItems - 1) / perPage) + 1;

  if (totalItems < perPage) {
    return null;
  } else {
    return <Row className={paginatorClassName}>
      <Col className="d-flex justify-content-start pt-4 pl-d-5 col-12 col-sm-12 col-md-6">
        {!showingAll && <p className="text-muted mb-0">
          Showing {(rangeStart + 1).toLocaleString()}-{rangeEnd.toLocaleString()} of {totalItems.toLocaleString()} {recordName}
        </p>}
      </Col>
      <Col className="d-flex justify-content-start justify-content-sm-start justify-content-md-end col-12 col-sm-12 col-md-6">
        {!showingAll && <span className="py-4 pr-2 text-muted">
          Page {currentPage + 1} of {totalPages}
        </span>}
        {!showingAll && <Pagination className="pt-3 pr-2 mb-0">
          <PaginationItem disabled={rangeStart === 0}>
            <PaginationLink first
                            onClick={() => rangeStart !== 0 ? handleChange(currentPage - 1) : 0}/>
          </PaginationItem>
          <PaginationItem disabled={rangeEnd === totalItems}>
            <PaginationLink last
                            onClick={() => rangeEnd !== totalItems ? handleChange(currentPage + 1) : null}/>
          </PaginationItem>
        </Pagination>}
        {allowShowAll && setShowingAll && <Pagination className="pt-3 pr-2 mb-0">
          <PaginationItem>
            <PaginationLink onClick={() => setShowingAll(!showingAll)}>
              {showingAll ? 'Show by page' : 'Show all'}
            </PaginationLink>
          </PaginationItem>
        </Pagination>}
      </Col>
    </Row>;
  }
};

export default Paginator;