import React, {KeyboardEvent, useState} from 'react';
import {FieldHelperProps} from 'formik';

import FormikInput from './FormikInput';
import {InputProps} from '../types';

const ALL_EXCEPT_NUMBERS_REGEX = /[^,.\d]/g;
const ALL_EXCEPT_NUMBERS_REGEX_WITH_NEGATIVES = /[^-,.\d]/g;

const NUMBER_KEYS = Array(10).fill(0).map((_, i) => i.toString());

type Props = InputProps & {
  name: string
  allowNegatives?: boolean
  integerPlaces: string | number
  decimalPlaces: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => void
}

// display value of input
export const formatDecimal = (value: string | number | null,
                              decimalPlaces: number = 2,
                              addTrailing0s: boolean = false,
                              allowNegatives: boolean = false): string => {
  const NON_NUMBERS_REGEX = allowNegatives ? ALL_EXCEPT_NUMBERS_REGEX_WITH_NEGATIVES : ALL_EXCEPT_NUMBERS_REGEX;
  if (value === null || value === '') {
    return '';
  } else if (value === '-') {
    return value;
  } else if (typeof value === 'string' && (value.split('.').length - 1) > 1) {
    // if value ends in multiple decimal points return integer portion + decimal point
    return value.split('.')[0] + '.';
  } else if (typeof value === 'string' && value[value.length - 1] === '.') {
    // if value ends in decimal point return string value so user can finish typing decimal portion
    return value;
  } else if (typeof value === 'string' &&
    value.indexOf('.') !== -1 &&
    value[value.length - 1] !== '.' &&
    NON_NUMBERS_REGEX.test(value)) {
    // if non numerical characters come after decimal point, parse them out and leave trailing decimal point
    // this handles the decimal point being stripped off after user types a non numerical char after the decimal point
    return formatDecimal(value.split('.')
      .map(val => val.replace(NON_NUMBERS_REGEX, ''))
      .join('.'), decimalPlaces);
  } else if (typeof value === 'string' && value.indexOf('.') !== -1 && value[value.length - 1] === '0') {
    // if value ends in 0 return string value so user can finish typing decimal portion
    // due to how js handles numbers with decimals
    const [integer, decimals] = value.split('.');
    const onlyDigits = integer.replace(NON_NUMBERS_REGEX, '')
      .replace(/,/g, '')
      .replace(/(?!^-)-/g, '');
    const numberValue = Number(onlyDigits);
    return numberValue.toLocaleString() + '.' + decimals.substring(0, decimalPlaces);
  } else {
    // otherwise return value as a number
    const stringValue = value.toString();
    const onlyDigits = stringValue.replace(NON_NUMBERS_REGEX, '')
      .replace(/,/g, '')
      .replace(/(?!^-)-/g, '');
    const numberValue = Number(onlyDigits);
    const wholePercentage = (addTrailing0s && numberValue % 1 === 0) ? '.' + '0'.repeat(decimalPlaces) : '';
    return isFinite(numberValue) ? numberValue.toLocaleString(undefined, {maximumFractionDigits: decimalPlaces}) + wholePercentage : '';
  }
};

// set formik value
export const setDecimalValue = (value: string | number | null, allowNegatives = false) => {
  const NON_NUMBERS_REGEX = allowNegatives ? ALL_EXCEPT_NUMBERS_REGEX_WITH_NEGATIVES : ALL_EXCEPT_NUMBERS_REGEX;
  if (value === null || value === '') {
    return null;
  } else if (value === '-') {
    return value;
  } else if (typeof value === 'string' && value.indexOf('.') !== -1 && value[value.length - 1] === '.') {
    // if last character of value is a decimal point
    // return raw value so formik/yup validates the field properly
    // this also handles the decimal point being stripped off the end while the user is typing.
    return value;
  } else if (typeof value === 'string' && value.indexOf('.') !== -1 && value[value.length - 1] !== '.' && NON_NUMBERS_REGEX.test(value)) {
    // if non numerical characters come after decimal point, parse them out and leave trailing decimal point
    // this handles the decimal point being stripped off after user types a non numerical char after the decimal point
    return value.split('.').map(val => val.replace(NON_NUMBERS_REGEX, '')).join('.');
  } else if (typeof value === 'number') {
    // return raw value if value is already a number
    return value;
  } else if (value[value.length - 1] === '0') {
    // if last character is 0, return as string to allow decimals due to how js handles numbers
    return value.replace(/,/g, '')
      .replace(/(?!^-)-/g, '');
  } else {
    // otherwise convert string to a number value
    const onlyDigits = value.replace(NON_NUMBERS_REGEX, '')
      .replace(/,/g, '')
      .replace(/(?!^-)-/g, '');
    const numberValue = Number(onlyDigits);
    return isFinite(numberValue) && onlyDigits !== '' ? numberValue : null;
  }
};

const FormikDecimalInput = ({
                              name,
                              integerPlaces,
                              decimalPlaces,
                              allowNegatives,
                              onChange,
                              ...formikInputProps
                            }: Props) => {
  const [focused, setFocused] = useState(false);
  const decimalPlacesValue = Number(decimalPlaces);
  const integerPlacesValue = Number(integerPlaces);
  const numberOfCommas = (Math.ceil(integerPlacesValue / 3) - 1);
  const maxLength = (allowNegatives ? 1 : 0) + integerPlacesValue + decimalPlacesValue + 1 + numberOfCommas;

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => {
    const target = e.target as HTMLInputElement;
    setFocused(false);
    const inputValue = target.value;
    if (inputValue[inputValue.length - 1] === '.') {
      // remove trailing decimal point if left on after value
      const valueWithoutTrailingPeriod = inputValue.slice(0, inputValue.length - 1);
      fieldActions.setValue(setDecimalValue(valueWithoutTrailingPeriod, allowNegatives));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const inputValue = target.value;
    if (e.key === '-') {
      // If negatives aren't allowed ignore - char
      if (!allowNegatives && target) {
        target.value = target.value.replace('-', '');
        e.preventDefault();
      }
    }
    // if value is at decimal places do not allow value to get set
    if (NUMBER_KEYS.includes(e.key) &&
      inputValue.indexOf('.') !== -1 &&
      inputValue.split('.').length > 1 &&
      inputValue.split('.')[1].length >= decimalPlacesValue) {
      e.preventDefault();
    }
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => {
    const target = e.target as HTMLInputElement;
    const inputValue = target.value;
    const addDecimalAtLength = (allowNegatives && inputValue.indexOf('-') !== -1 ?
      inputValue.length - 1 : inputValue.length);
    const shouldAddDecimal = addDecimalAtLength === (integerPlacesValue + numberOfCommas);
    if (NUMBER_KEYS.includes(e.key)) {
      // if value is at max integer places, add a decimal point to value
      if (inputValue.indexOf('.') === -1 && shouldAddDecimal) {
        fieldActions.setValue(setDecimalValue(inputValue + '.', allowNegatives));
      }
    } else if (e.key === 'Backspace' && shouldAddDecimal) {
      // if user backspaces at decimal point, remove preceding character as well
      target.value = inputValue.substring(0, inputValue.length - 1);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => {
    if (onChange) {
      onChange(e, fieldActions);
    }
    fieldActions.setValue(setDecimalValue(e.target.value, allowNegatives));
  };

  return <FormikInput {...formikInputProps}
                      type="text"
                      name={name}
                      format={(value: string) => formatDecimal(value, decimalPlacesValue, !focused, allowNegatives)}
                      onBlur={(e: React.FocusEvent, fieldActions: FieldHelperProps<any>) => handleBlur(e as React.FocusEvent<HTMLInputElement>, fieldActions)}
                      onChange={handleOnChange}
                      onKeyUp={handleKeyUp}
                      onFocus={() => setFocused(true)}
                      onKeyDown={handleKeyDown}
                      maxLength={maxLength}
                      noFormikOnChange={true}
  />;
};

export default FormikDecimalInput;