enum MiSuiteJurisdiction {
  SSO_APP = 'SSO_APP',
  CERTS_APP = 'CERTS_APP',
  COUNTY_BOOK_APP = 'COUNTY_BOOK_APP',
  PA_660_APP = 'PA_660_APP',
  MEG_APP = 'MEG_APP',
  MEGA_APP = 'MEGA_APP',
  PRE_APP = 'PRE_APP',
  MICHIGAN = 'MICHIGAN',
  REASON_CONSULTING_CORP = 'REASON_CONSULTING_CORP'
}

export default MiSuiteJurisdiction;