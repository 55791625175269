import React, {memo, useCallback, useEffect, useState} from 'react';

import {SsoSpringRestApi} from '../restApi';
import ConfirmationModal from './ConfirmationModal';
import {Duration, DurationType} from '../util';

type Props = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  ssoApi: SsoSpringRestApi
}

const SsoSessionExpirationModal = ({
                                     isOpen,
                                     setIsOpen,
                                     ssoApi
                                   }: Props) => {
  const [timeLeftDisplayValue, setTimeLeftDisplayValue] = useState("");

  const checkActivity = useCallback(() => {
    // If open, but session data got cleared in another tab the close the modal and redirect to SSO Login
    if (isOpen && !ssoApi.sessionDataExists()) {
      setIsOpen(false)
      void ssoApi.redirectToLogin();
    }

    // If last activity got updated by a server request after modal and modal was open then close it
    if (isOpen && ssoApi.sessionTimeLeftGreaterThanWarningThreshold()) {
      setIsOpen(false);
    }
  }, [
    isOpen,
    setIsOpen,
    ssoApi
  ]);

  const onContinueClick = useCallback(() => {
    ssoApi.bumpLastActivity();
    setIsOpen(false);
  }, []);

  const onSignOutClick = useCallback(() => {
    setIsOpen(false);
    void ssoApi.signOut();
  }, []);

  useEffect(() => {
      // Check every 5 seconds
      const interval = setInterval(checkActivity, Duration.of(5, DurationType.SECONDS));
      // Clear interval if inputs change
      return () => clearInterval(interval);
    },
    [
      checkActivity
    ]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeftInSeconds = Math.round(ssoApi.timeLeftInSession() / 1000);
      const timeLeftInMinutes = Math.floor(timeLeftInSeconds / 60)

      if(timeLeftInSeconds > 60){
        setTimeLeftDisplayValue( `${timeLeftInMinutes} ${timeLeftInMinutes > 1 ? 'minutes' : 'minute' } ${timeLeftInSeconds - 60} seconds`)
      } else {
        setTimeLeftDisplayValue( `${timeLeftInSeconds} seconds`);
      }
    })
    return () => clearInterval(interval);
  }, [
    ssoApi.timeLeftInSession
  ]);

  return (
    <ConfirmationModal isOpen={isOpen}
                       title="Session Timeout"
                       cancelButtonText="Continue Session"
                       cancelButtonColor="success"
                       cancelCallback={onContinueClick}
                       confirmButtonColor="danger"
                       confirmButtonText="Sign Out"
                       confirmCallback={onSignOutClick}>
      <p>Your session is expiring in {timeLeftDisplayValue}. Would you like to continue?</p>
    </ConfirmationModal>
  );
};

export default memo(SsoSessionExpirationModal);