import React, {memo, useCallback} from 'react';

import {Formik, FormikHelpers} from 'formik';
import {Form} from 'reactstrap';
import {debounce} from 'lodash';

import FormikInput from './FormikInput';

type SearchFormFields = {
  searchText: string
}

type Props = {
  disabled: boolean,
  onSubmit: (searchText: string) => void
  disableFloatingLabel?: boolean
}

const FormikSearchInput = ({
                             disabled,
                             onSubmit,
                             disableFloatingLabel = false
                           }: Props) => {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSubmit = useCallback(
    debounce((value: string, formikHelpers: FormikHelpers<SearchFormFields>) => {
      onSubmit(value);
      formikHelpers.setSubmitting(false);
    }, 250),
    [onSubmit]
  );

  const handleSubmit = (values: SearchFormFields, formikHelpers: FormikHelpers<SearchFormFields>) => {
    debounceSubmit(values.searchText, formikHelpers);
  };

  return (
    <Formik initialValues={{searchText: ''}}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form onSubmit={(e) => e.preventDefault()} autoComplete="off">
          <FormikInput name="searchText"
                       labelText="Search"
                       icon={{name: 'search', text: 'Search'}}
                       onKeyUp={(e: React.KeyboardEvent) => {
                         if (e.key !== 'Enter') {
                           handleSubmit(formikProps.values, formikProps);
                         }
                       }}
                       disableFloatingLabel={disableFloatingLabel}
                       disableOnSubmit={false}
                       disabled={disabled}/>
        </Form>
      )}
    </Formik>
  );
};

export default memo(FormikSearchInput);