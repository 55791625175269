import React from 'react';
import {FieldHelperProps} from 'formik';

import FormikInput from './FormikInput';
import {InputProps} from '../types';

const ALL_EXCEPT_NUMBERS_REGEX = /[^\d]/g;

type Props = InputProps & {
  name: string
  extensionLength?: number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, formikActions?: FieldHelperProps<any>) => void
}

const formatMaskInputValue = (value: string, format: string) => {
  const sanitizedValue = value.replace(ALL_EXCEPT_NUMBERS_REGEX, '');

  let i = 0;
  let lastReplacedIndex = -1;
  // find each match of a field to fill in format denoted by # characters
  const filledMask = format.replace(/#/g, (_, j) => {
    // iterate through # characters in the mask format
    // for each matched index of a # char, push the corresponding char with same index from value.
    if (i >= sanitizedValue.length) {
      return '#';
    }
    // save the index of the last match of #.
    // this is the last char to replace and return
    lastReplacedIndex = j;
    return sanitizedValue[i++];
  });

  return filledMask.substring(0, lastReplacedIndex + 1);
};

// display value of phone number
export const formatPhoneValue = (value: string) => formatMaskInputValue(value, '(###) ###-####');
export const formatPhoneExtensionValue = (value: string, extensionLength: number) => {
  let mask = '(###) ###-#### x';
  for (let i = 0; i < extensionLength; i++) {
    mask += '#';
  }
  return formatMaskInputValue(value, mask);
};

// set formik phone value
export const setPhoneValue = (value: string) => {
  return formatPhoneValue(value);
};

export const setPhoneExtensionValue = (value: string, extensionLength: number) => {
  return formatPhoneExtensionValue(value, extensionLength);
};

const FormikPhoneInput = ({
                            name,
                            extensionLength,
                            onChange,
                            ...formikInputProps
                          }: Props) => {
  const format = (value: string) => extensionLength ?
    formatPhoneExtensionValue(value, extensionLength) :
    formatPhoneValue(value);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => {
    if (onChange) {
      onChange(e, fieldActions);
    }
    const value = extensionLength ? setPhoneExtensionValue(e.target.value, extensionLength) : setPhoneValue(e.target.value);
    fieldActions.setValue(value);
  };

  return <FormikInput {...formikInputProps}
                      name={name}
                      type="text"
                      format={format}
                      onChange={handleOnChange}
                      noFormikOnChange={true}
  />;
};

export default FormikPhoneInput;