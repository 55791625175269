import React, {useState} from 'react';
import {CustomInput, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import {FieldHelperProps, useField, useFormikContext} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {InputProps} from '../types';

const FormikInput = ({
                       id,
                       format,
                       name,
                       type = 'text',
                       bsSize,
                       formGroupClass,
                       labelText,
                       disableFloatingLabel = false,
                       ariaLabel,
                       icon,
                       disabled,
                       disableOnSubmit = true,
                       onBlur,
                       onKeyUp,
                       onChange,
                       noFormikOnChange,
                       ...otherProps
                     }: InputProps) => {
  const [field, meta, helpers] = useField(name);
  const {isSubmitting, validateOnMount} = useFormikContext();
  const [focused, setFocused] = useState(false);
  const Tag = (type === 'file' ? CustomInput : Input) as React.ElementType;
  const isInvalid = validateOnMount ? !!meta.error : !!(meta.error && meta.touched);
  const idToUse = id ? id : `${name}Input`;

  // Needed in order to handle floating and static label CSS
  let derivedGroupClass = formGroupClass ? formGroupClass : '';
  if (!field.value && !focused && !disableFloatingLabel && (type !== 'file' && type !== 'textarea')) {
    derivedGroupClass += ' has-placeholder';
    if (bsSize) {
      derivedGroupClass += ` ${bsSize}`;
    }
  }

  const renderLabel = () => {
    if (labelText) {
      let labelClass = 'label-static';
      // File inputs and textarea should always be static, and for
      // all other inputs a label should be static if has focus and no value.
      // Otherwise display the label as a placeholder.
      if (type !== 'file' && type !== 'textarea' && !disableFloatingLabel && !focused && (field.value === '' || field.value === undefined || field.value === null)) {
        labelClass = 'label-placeholder';
      }
      labelClass = labelClass === 'label-static' && focused ? `${labelClass} focused` : labelClass;
      labelClass = isInvalid ? `${labelClass} is-invalid` : labelClass;

      if (!icon) {
        return (
          <Label htmlFor={idToUse}
                 className={labelClass}
                 size={bsSize}>
            {labelText}
          </Label>
        );
      } else {
        return (
          <Label htmlFor={idToUse}
                 className={labelClass}
                 size={bsSize}>
            <FontAwesomeIcon icon={icon.name as IconProp}/> {labelText}
          </Label>
        );
      }
    }
    return null;
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>, helpers: FieldHelperProps<any>) => {
    // Don't perform Formik's default on change if noFormikOnChange is true
    if (!noFormikOnChange) {
      await field.onChange(e);
    }

    // Call additional on change function if provided
    if (onChange) {
      onChange(e, helpers);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>, helpers: FieldHelperProps<any>) => {
    if (onBlur) {
      onBlur(e, helpers);
    }
    setFocused(false);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, helpers: FieldHelperProps<any>) => {
    if (onKeyUp) {
      onKeyUp(e, helpers);
    }
  };

  return (
    <FormGroup onFocus={() => setFocused(true)}
               onBlur={(e: React.FocusEvent<HTMLDivElement>) => handleBlur(e, helpers)}
               className={derivedGroupClass}>
      {renderLabel()}
      <Tag {...field}
           {...otherProps}
           id={idToUse}
           type={type}
           value={format ? format(field.value) : field.value}
           aria-label={ariaLabel ? ariaLabel : labelText}
           onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, helpers)}
           onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyUp(e, helpers)}
           bsSize={bsSize}
           disabled={disabled || (isSubmitting && disableOnSubmit)}
           invalid={isInvalid}/>
      <FormFeedback>{meta.error}</FormFeedback>
    </FormGroup>
  );
};

export default FormikInput;
