enum MiSuiteRole {
  ADMIN = 'ADMIN',
  CSR = 'CSR',
  DEPARTMENTAL_TECHNICIAN = 'DEPARTMENTAL_TECHNICIAN',
  AUDITOR = 'AUDITOR',
  AUDIT_SUPPORT = 'AUDIT_SUPPORT',
  BRANCH_MANAGER = 'BRANCH_MANAGER',
  FOS_SUPERVISOR = 'FOS_SUPERVISOR',
  FOS_ANALYST = 'FOS_ANALYST',
  EQUALIZATION_CLERK = 'EQUALIZATION_CLERK',
  EQUALIZATION_DIRECTOR = 'EQUALIZATION_DIRECTOR',
  EQUALIZATION_DEPUTY = 'EQUALIZATION_DEPUTY',
  ASSESSOR_OF_RECORD = 'ASSESSOR_OF_RECORD',
  ASSESSING_CLERK = 'ASSESSING_CLERK',
  ASSESSING_DEPUTY = 'ASSESSING_DEPUTY',
  TOWNSHIP_SUPERVISOR = 'TOWNSHIP_SUPERVISOR',
  CITY_MANAGER = 'CITY_MANAGER',
  MAYOR = 'MAYOR',
};

export default MiSuiteRole;